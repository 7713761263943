/* Left slide*/
.single-slide[data-order="0"] {
  transform: scale(0.8, 0.8);
  z-index: 1;
  opacity: 0.7;
  order: 1;
}

/* Middle slide */
.single-slide[data-order="1"] {
  z-index: 3;
  opacity: 1;
  order: 2;
}

/* Right slide*/
.single-slide[data-order="2"] {
  transform: scale(0.8, 0.8);
  z-index: 2;
  opacity: 0.7;
  order: 3;
}
